.swiper-container-fade.swiper-container-free-mode .swiper-slide{
  transition-timing-function:ease-out
}

.swiper-container-fade .swiper-slide{
  pointer-events:none;
  transition-property:opacity
}

.swiper-container-fade .swiper-slide .swiper-slide{
  pointer-events:none
}

.swiper-container-fade .swiper-slide-active,.swiper-container-fade .swiper-slide-active .swiper-slide-active{
  pointer-events:auto
}
